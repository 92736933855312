import React, { useState, useEffect, useReducer } from 'react';
import { Toaster } from 'react-hot-toast';
import Loader from 'components/Loader';
import { getPmsResults, getPmsResultsCount } from 'apis/robodialer';

import PMSResultsTable from './pms-results-table';
import PMSDetailedResultsTable from './pms-detailed-results-table';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { RobodialerUser } from '../types/types';

type PathType = {
  name: string;
  href: string;
  current: boolean;
};

type PmsProps = {
  archived: boolean;
};

type PageProps = {
  userData: RobodialerUser | null;
};


const ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  SET_PMS_RESULTS: 'SET_PMS_RESULTS',
  SET_RESULTS_OVERVIEW: 'SET_RESULTS_OVERVIEW',
  SET_QUERY_RESULT: 'SET_QUERY_RESULT',
  SET_SELECTED_PATIENT: 'SET_SELECTED_PATIENT'
};


const initialState = {
  loading: true,
  pmsResults: null,
  resultsOverview: null,
  queryResult: null,
  selectedPatient: null
};


function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload };
    case ACTIONS.SET_PMS_RESULTS:
      return { ...state, pmsResults: action.payload };
    case ACTIONS.SET_RESULTS_OVERVIEW:
      return { ...state, resultsOverview: action.payload };
    case ACTIONS.SET_QUERY_RESULT:
      return { ...state, queryResult: action.payload };
    case ACTIONS.SET_SELECTED_PATIENT:
      return { ...state, selectedPatient: action.payload };
    default:
      return state;
  }
}

export default function PMSPage({ archived }: PmsProps) {
  const [props] = useOutletContext<Array<PageProps>>();
  const { search } = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  

  useEffect(() => {
    const fetchResults = async () => {
      dispatch({ type: ACTIONS.SET_LOADING, payload: true });
      getPmsResultsCount(archived).then((r) => {
        dispatch({ type: ACTIONS.SET_RESULTS_OVERVIEW, payload: r?.data });
      });

      const callId = (search ?? '')?.substring(1);
      const all_patients_response = await getPmsResults(0, 15, archived);
      dispatch({ type: ACTIONS.SET_PMS_RESULTS, payload: { limit: all_patients_response?.data?.limit, offset: all_patients_response?.data?.offset, calls: all_patients_response?.data?.calls } });
      
      if (callId){
        const selected_call = await getPmsResults(0, 1, archived, callId);
        const selectedCallIndex = selected_call?.data?.calls.findIndex((call) => call.callId === callId);
        if (selectedCallIndex !== -1) {
          dispatch({ type: ACTIONS.SET_QUERY_RESULT, payload: selected_call.data.calls[selectedCallIndex] });
          dispatch({ type: ACTIONS.SET_SELECTED_PATIENT, payload: selectedCallIndex });
        }
      }
      
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    };

    fetchResults();
  }, [archived, search]);

  const { loading, pmsResults, resultsOverview, queryResult, selectedPatient } = state;

  return (
    <>
      <Toaster toastOptions={{ duration: 12000 }} />
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Loader />
        </div>
      ) : null}
      {!loading ? (
        <div className="pb-4">
          { typeof selectedPatient === 'undefined' || selectedPatient === null ? (
            <PMSResultsTable
              archived={archived ?? false}
              setLoading={(loading) => dispatch({ type: ACTIONS.SET_LOADING, payload: loading })}
              pmsResults={pmsResults}
              selectedPatient={selectedPatient}
              setSelectedPatient={(patient) => dispatch({ type: ACTIONS.SET_SELECTED_PATIENT, payload: patient })}
              total={resultsOverview?.total ?? 0}
              refresh={async (offset, limit) => {
                dispatch({ type: ACTIONS.SET_LOADING, payload: true });
                const response = await getPmsResults(offset, limit, archived);
                dispatch({ type: ACTIONS.SET_PMS_RESULTS, payload: { limit: response?.data?.limit, offset: response?.data?.offset, calls: response?.data?.calls } });
                dispatch({ type: ACTIONS.SET_LOADING, payload: false });
              }}
            />
          ) : (
            <PMSDetailedResultsTable
              patient={queryResult ?? pmsResults?.calls[selectedPatient] ?? {}}
              selectedPatient={selectedPatient}
              setSelectedPatient={(patient) => dispatch({ type: ACTIONS.SET_SELECTED_PATIENT, payload: patient })}
            />
          )}
        </div>
      ) : null}
    </>
  );
}