import React, { useEffect, useState } from "react";
import { downloadInputsCsv } from 'utils/doiwnload';
import Loader from 'components/Loader';
import Select from "react-select";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { ReactComponent as InfoIcon } from "sign-up/superpay/assets/info.svg";
import { updateScriptDoc, getScriptInputs } from "apis/robodialer";
import { Script } from "types/types";
import { generateCSV } from "../run-script/upload-csv-page";


// custom styles
const customStyles = {
  content: {
    height: "max(80%,600px)",
    maxWidth: "max(80%,600px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  refresh: () => void;
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedScript: string | null;
  scriptData: Script | null;
  setSelectedScript: React.Dispatch<React.SetStateAction<string | null>>;
  
}

interface FormValues {
  name: string;
}
// components
export default function ViewInputsModal({ modalIsOpen, setModalIsOpen, scriptData, setSelectedScript, selectedScript, refresh }: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [inputs, setInputs] = useState({});
  const [mode, setMode] = useState<string>('all');
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
    setSelectedScript(null);
  };
  useEffect(() => {
    const fetch = async () => {
      const { data } = await getScriptInputs((scriptData?.type ?? '')?.replaceAll(' ', '-').toLocaleLowerCase())
      setInputs(data);
    }
    if(scriptData && scriptData?.type){
      fetch()
    }

  }, [scriptData?.type])

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Script Inputs
          </nav>
          <div className="flex-grow overflow-y-auto p-9 text-superbill-jacarta">
            You'll need to provide the following inputs when placing calls. Need an example CSV inputs file? 
            <button type='button' className="ml-2 text-superbill-ultramarine hover-underline-animation cursor-pointer" onClick={(e) => {
              if(e.preventDefault) {
                e.preventDefault();
              }
              generateCSV(inputs,(scriptData?.questions ?? []), scriptData?.name) 
              
            }}>
                Download our template.
              </button>
          <div className=' mt-1 w-full'>
            <div className='border border-superbill-soap rounded w-fit p-1 mt-3'>
              <button onClick={() => {setMode('all')}} className={`py-1 px-2 rounded ease-in-out duration-150 ${mode === 'all' ? `bg-superbill-lavender text-superbill-indigo` : ``}`}>All Inputs</button>
              <button onClick={() => {setMode('required')}} className={`py-1 px-2 rounded ease-in-out duration-150 ${mode === 'required' ? `bg-superbill-lavender text-superbill-indigo` : ``}`}>Required</button>
              <button onClick={() => {setMode('optional')}} className={`py-1 px-2 rounded ease-in-out duration-150 ${mode === 'optional' ? `bg-superbill-lavender text-superbill-indigo` : ``}`}>Optional</button>
            </div>
          <div className='w-full my-1 mt-3 bg-white rounded border border-superbill-soap divide-y divide-superbill-soap font-sans overflow-hidden'>
          <div className={`grid ${mode === 'all' ? 'grid-cols-8' : 'grid-cols-6'} gap-4 p-2 bg-superbill-anti-flash-white content-center items-center`}>
              <div className={`${mode === 'all' ? 'col-span-3' : 'col-span-2'} text-superbill-jacarta text-base font-semibold`}>Input</div>
              <div className={`${mode === 'all' ? 'col-span-1' : 'hidden'} text-superbill-jacarta text-base font-semibold overflow-hidden`}>Required</div>
              <div className='text-superbill-jacarta text-base font-semibold'>Description</div>

            </div>
          {mode === 'all' ? 
          <>
          {Object.keys(inputs).map((key) => {
            return (
          <div className='grid grid-cols-8 gap-4 p-2 content-center items-center hover:bg-superbill-lavender/50 ease-in-out duration-150' key={key} >
              <div className='col-span-3 text-superbill-jacarta text-base font-semibold break-words'>{key}</div>
              <div className='col-span-1 text-superbill-jacarta text-base'>{inputs[key].required? 'True' : 'False' }</div>
              <div className='col-span-4 text-superbill-jacarta text-base'>{inputs[key].description ?? ''}</div>

            </div>
            )
          })}
          </>
          : 
          mode === 'required' ?
          <>
          {Object.keys(inputs).map((key) => {
            if (inputs[key].required === true && key) {
            return (
          <div className='grid grid-cols-6 gap-4 p-2 content-center items-center hover:bg-superbill-lavender/50 ease-in-out duration-150' key={key} >
              <div className='col-span-2 text-superbill-jacarta text-base font-semibold break-words'>{key}</div>
              <div className='col-span-4 text-superbill-jacarta text-base'>{inputs[key].description ?? ''}</div>

            </div>
            )
          }
          })}
          </>
          :
          mode === 'optional' ?
          <>
          {Object.keys(inputs).map((key) => {
            if (inputs[key].required === null || inputs[key].required === false && key) {
            return (
          <div className='grid grid-cols-6 gap-4 p-2 content-center items-center hover:bg-superbill-lavender/50 ease-in-out duration-150' key={key} >
              <div className='col-span-2 text-superbill-jacarta text-base font-semibold break-words'>{key}</div>
              <div className='col-span-4 text-superbill-jacarta text-base'>{inputs[key].description ?? ''}</div>

            </div>
            )
          }
          })}
          </>
          :
          <></>
        }
          </div>
            {/* <div className="bg-superbill-anti-flash-white p-4 rounded mt-4">
                <div className="font-semibold">Optional</div>
                <ul className="ml-4 list-disc">
                    {Object.keys(inputs).map((key) => {
                        if (!inputs[key].required && inputs[key].required === false && key) {
                        return (
                            <li className="text-sm">{key}</li>
                        );
                        }
                    })}
                    </ul>
                    </div> */}
              </div>

          </div>
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { setModalIsOpen(false); setMode('all')}}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
