import React, { useEffect, useState, useContext, useRef } from 'react';
import RetoolPage from './components/retoolPage';
import TelnyxRTCWrapper from './components/telnyx-wrapper';

export default function SidekickContent() {



  const [showWaitingRoom, setShowWaitingRoom] = useState(false);
  const [isCallReady, setIsCallReady] = useState(false);

  const [displayedComponent, setDisplayedComponent] = useState(<TelnyxRTCWrapper />)



  useEffect(() => {
    if (showWaitingRoom) {
      setDisplayedComponent(<TelnyxRTCWrapper />);
    }
  }, [showWaitingRoom])


  useEffect(() => {
    if (isCallReady) {
      setDisplayedComponent(<RetoolPage />);
      setShowWaitingRoom(false);
    }
  }, [isCallReady])



  return (
    <>
      {displayedComponent}
    </>
  );
}
