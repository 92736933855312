import React, { useState, useEffect, useCallback } from 'react';
import { Toaster } from 'react-hot-toast';
import Loader from 'components/Loader';
import { getPmsNewPatients,  getScripts, updateScriptDoc } from 'apis/robodialer';
import ScriptBuilderHomeHeader from './dashboard/home-header';
import { RobodialerUser, Script,  } from 'types/types';
import ScriptCard from './dashboard/script-card';
import NewScriptModal from './dashboard/new-script-modal';
import { useNavigate, useOutletContext } from "react-router-dom";
import RunScriptModal from './run-script/run-script-modal';
import DeletePolicyModal from './dashboard/delete-script-modal';

import PmsNewPatientsTable from './pms-new-patients-table';
import AutoVerificationModal from './auto-verification/auto-verification-modal';
import RenameScriptModal from './dashboard/rename-script-modal';
import ViewInputsModal from './dashboard/view-inputs-modal';
import PlaceDemoCallsModal from './place-demo-calls/place-demo-calls-modal';
import DuplicateScriptModal from './duplicate-script/duplicate-script-modal';
import DownloadInputsModal from './dashboard/download-inputs';
import DefineInputsModal from './dashboard/define-inputs-modal';
import { getWebhookData } from 'apis/robodialer';


type ScriptBuilderHomeProps = {
  userData: RobodialerUser | null,
  setUserData: (userDate: RobodialerUser | null) => void
}
export default function ScriptBuilderHome() {
  const [props] = useOutletContext<Array<ScriptBuilderHomeProps>>();
  const { userData } = props
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [placeDemoCallModalOpenState, setPlaceDemoCallModalOpenState] = useState<boolean>(false);
  const [scriptData, setScriptData] = useState<Array<Script> | null>(null);
  const [newPatients, setNewPatients] = useState<any | null>({});

  const [selectedScriptId, setSelectedScriptId] = useState<string | null>(null);
  const [newScriptOpenState, setNewScriptOpenState] = useState<boolean>(false);
  const [renameScriptOpenState, setRenameScriptOpenState] = useState<boolean>(false);
  const [viewInputsOpenState, setViewInputsOpenState] = useState<boolean>(false);
  const [defineInputsOpenState, setDefineInputsOpenState] = useState<boolean>(false);
  const [downloadInputsOpenState, setDownloadInputsOpenState] = useState<boolean>(false);
  const [autoVerifOpenState, setAutoVerifOpenState] = useState<boolean>(false);
  const [deleteScriptOpenState, setDeleteScriptOpenState] = useState<boolean>(false);
  const [runScriptOpenState, setRunScriptOpenState] = useState<boolean>(false);
  const [duplicateScriptOpenState, setDuplicateScriptOpenState] = useState<boolean>(false);
  const [statusIsLive, setStatusIsLive] = useState<boolean>(true);


  const [selectedScript, setSelectedScript] = useState<{
    id: string;
    action: 'delete' | 'run' | 'duplicate';
  } | null>(null);



  useEffect(() => {
    const fetchScripts = async () => {
      //console.log('fetchScripts');
      setLoading(true)
      const { data } = await getScripts();
      setScriptData(data?.sort((item) => item.pms ? -1 : 1));
      //if(userData?.pmsActive){
        const response = await getPmsNewPatients();
        if (response.httpCode === 200) {
          setNewPatients(response?.data?.patients);
        }
        else{
          setNewPatients({});
        }
      //}
      const webhookData = await getWebhookData()
      if (webhookData.data.status) {
        setStatusIsLive(webhookData.data.status==='live');
      } else {
        setStatusIsLive(true)
      }
      setLoading(false)
    }
    if (!scriptData) {
      fetchScripts()
    }
  }, []);


  /* useEffect(() => {

    if (selectedScript) {
      if (selectedScript?.action === 'run') {
        setRunScriptOpenState(true)
      }
      if (selectedScript?.action === 'duplicate') {
        setDuplicateScriptOpenState(true)
      }
    }
  }, [selectedScript]) */


  const editScript = useCallback((id: string) => {
    navigate(`/super-dial?id=${id}`);
  },[])

  const duplicateScript = useCallback((id: string) => {
    setSelectedScriptId(id);
    setDuplicateScriptOpenState(true);
  }, []);


  const runScript = useCallback((id: string) => {
    setSelectedScriptId(id)
    setRunScriptOpenState(true)
  },[])

  const deleteScript = useCallback((id: string) => {
    setSelectedScriptId(id)
    setDeleteScriptOpenState(true)
  },[])

  const renameScript = useCallback((id: string) => {
    setSelectedScriptId(id)
    setRenameScriptOpenState(true)
  },[])
  const viewInputs = useCallback((id: string) => {
    setSelectedScriptId(id)
    setViewInputsOpenState(true)
  },[])
  const defineInputs = useCallback((id: string) => {
    setSelectedScriptId(id)
    setDefineInputsOpenState(true)
  },[])
  const downloadInputs = useCallback((id: string) => {
    setSelectedScriptId(id)
    setDownloadInputsOpenState(true)
  },[])

  const [isPmsAutoVerificationEnabled, setIsPmsAutoVerificationEnabled] = useState<boolean>(userData?.pmsAutoVerificationEnabled ?? false);

  return (
    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className='w-full h-full flex justify-center items-center'>
            {' '}
            <Loader />{' '}
          </div>
        </>
      ) : null}
      {!loading ? (
        <div className='bg-superbill-background-base'>
          <ScriptBuilderHomeHeader openDemoCallModal={() => {
            setPlaceDemoCallModalOpenState(true)
          }} status={userData?.status ?? 'not live'} pmsActive={userData?.pmsActive} newPatients={Object.keys(newPatients ?? {})?.length} unlimitedCalls={userData?.unlimitedCalls!} callsRemaining={
            userData?.callsRemaining || 5
          } />

          <div className=' mx-5 md:my-5 md:mx-20 lg:max-w-7xl lg:mx-auto px-2'>
            {userData?.pmsActive ?
              <>
                <div className='my-5'>
                  <PmsNewPatientsTable 
                  pmsAutoVerificationEnabled={isPmsAutoVerificationEnabled}
                  openModal={() => {
                    setAutoVerifOpenState(true)
                  }} pmsNewPatients={newPatients} setPmsNewPatients={setNewPatients} refresh={async () => {
                    setLoading(true)
                    const response = await getPmsNewPatients();
                    setNewPatients(response?.data?.patients);
                    setLoading(false)
                  }} />
                </div>
              </>
              : null}
              {
                statusIsLive ?
                null
                :
                <div className='text-superbill-jacarta bg-superbill-lavender p-2 rounded mb-2 font-jakarta text-sm flex items-center sm:mt-3'>
                  <div className='bg-superbill-indigo py-1 px-2 text-white rounded mr-3 text-center'><strong>Demo Portal</strong></div>
                  You'll be able to create unlimited scripts in the Demo Portal. When you're ready to make calls, reach out to support@superdial.ai
                </div>
              }
            <div className="flex justify-between items-center w-full border-b border-superbill-soap py-2">
              <div className='text-superbill-jacarta font-bold text-lg font-jakarta'>Scripts</div>
              <button className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300' onClick={() => { setNewScriptOpenState(true) }}>
                New Script
              </button>
            </div>
            {0 < scriptData?.length! ?
              <div className='flex justify-start items-center flex-row w-full flex-wrap'>
                {scriptData?.map((script, index) => {
                  return <div className='mr-2 mb-2 grow max-w-[420px]' key={index}>
                    {script?.id ? 
                      <ScriptCard
                        onDuplicate={duplicateScript}
                        onEdit={editScript}
                        onRun={runScript}
                        onDelete={deleteScript}
                        onRename={renameScript}
                        onViewInputs={viewInputs}
                        onDefineInputs={userData?.featureFlags?.displayScriptLevelInputs === true ? defineInputs : null}
                        onDownloadInputs={downloadInputs}
                        createdAt={script?.createdAt}
                        id={script?.id}
                        pmsCard={script?.pms}
                        name={script?.name}
                        questionsCount={script?.questionsCount ?? 0}
                        status={statusIsLive}
                      />
                    :null}
                  </div>
                })}
              </div>
              :
              <div className='p-5 text-superpay-light-text-gray text-base font-sans text-center'>No scripts created yet
                <div>Click “New Script” to get started</div></div>
            }
          </div>
        </div>
      ) : null}

      {runScriptOpenState && selectedScriptId && scriptData && userData ?
        <RunScriptModal maxRows={50}
          isOpen={runScriptOpenState} selectedScriptId={selectedScriptId} onClose={() => {
            setRunScriptOpenState(false)
            setSelectedScript(null)
        }} scriptData={scriptData} onSuccess={async () => {
            setRunScriptOpenState(false)
            setSelectedScript(null)
          //const response = await getBatches();
          //setBatches(response.data);
          //updateAndSortBatches(response.data)
        }} />
      :null}
      {duplicateScriptOpenState && selectedScriptId && scriptData ?
        <DuplicateScriptModal isOpen={duplicateScriptOpenState} selectedScriptId={selectedScriptId} onClose={async () => {
          setSelectedScript(null)
          setDuplicateScriptOpenState(false)
        }} scriptData={scriptData} onSuccess={async () => {
          setLoading(true)
          setDuplicateScriptOpenState(false)
          const { data } = await getScripts();
          setScriptData(data?.sort((item) => item.pms ? -1 : 1));
          setSelectedScript(null)
          setLoading(false)
        }} />
      :null}
      {newScriptOpenState ?
        <NewScriptModal modalIsOpen={newScriptOpenState} setModalIsOpen={setNewScriptOpenState} />
      :null}

      
      {renameScriptOpenState && selectedScriptId ? 
        <RenameScriptModal selectedScript={selectedScriptId} setSelectedScript={setSelectedScriptId} modalIsOpen={renameScriptOpenState} setModalIsOpen={setRenameScriptOpenState} scriptName={
          scriptData && scriptData?.find ? scriptData?.find(item => item.id === selectedScriptId)?.name! : null
        } refresh={async () => {
          setLoading(true)
          const { data } = await getScripts();
          setScriptData(data?.sort((item) => item.pms ? -1 : 1));
          setLoading(false)
        }}
        closeModal={() => {
          setRenameScriptOpenState(false)
          //setSelectedScriptId(null)
        }}
        />
      :null}
      {viewInputsOpenState && selectedScriptId && scriptData? 
        <ViewInputsModal selectedScript={selectedScriptId} setSelectedScript={setSelectedScriptId} scriptData={scriptData?.find(item => item.id === selectedScriptId) || null} modalIsOpen={viewInputsOpenState && selectedScriptId !== null} setModalIsOpen={setViewInputsOpenState} refresh={async () => {
          setLoading(true)
          const { data } = await getScripts();
          setScriptData(data?.sort((item) => item.pms ? -1 : 1));
          setLoading(false)
        }}/>
      : null}
      {defineInputsOpenState && selectedScriptId && scriptData? 
        <DefineInputsModal selectedScript={selectedScriptId} setSelectedScript={setSelectedScriptId} scriptData={scriptData?.find(item => item.id === selectedScriptId) || null} modalIsOpen={defineInputsOpenState && selectedScriptId !== null} setModalIsOpen={setDefineInputsOpenState} refresh={async () => {
          setLoading(true)
          const { data } = await getScripts();
          setScriptData(data?.sort((item) => item.pms ? -1 : 1));
          setLoading(false)
        }}/>
      : null}
      {downloadInputsOpenState && selectedScriptId && scriptData? 
        <DownloadInputsModal selectedScript={selectedScriptId} setSelectedScript={setSelectedScriptId} scriptData={scriptData?.find(item => item.id === selectedScriptId) || null} modalIsOpen={downloadInputsOpenState && selectedScriptId !== null} setModalIsOpen={setDownloadInputsOpenState} refresh={async () => {
          setLoading(true)
          const { data } = await getScripts();
          setScriptData(data?.sort((item) => item.pms ? -1 : 1));
          setLoading(false)
        }}/>
      : null}
      <AutoVerificationModal isOpen={autoVerifOpenState} onClose={() => {
        setAutoVerifOpenState(false)
      }}
       onSuccess={() => {
        setIsPmsAutoVerificationEnabled(true)
       }}
      />
      <PlaceDemoCallsModal
        isOpen={placeDemoCallModalOpenState}
        onClose={() => {
          setPlaceDemoCallModalOpenState(false)
        }} />
      <DeletePolicyModal modalIsOpen={deleteScriptOpenState} scriptId={selectedScriptId} scriptName={
        scriptData && scriptData?.find ? scriptData?.find(item => item.id === selectedScriptId)?.name! : null
      }
        closeModal={() => {
          setDeleteScriptOpenState(false)
          //setSelectedScriptId(null)
        }}
        onDeleteScript={async () => {
          let { httpCode } = await updateScriptDoc(selectedScriptId, {
            hidden: true
          });
          setDeleteScriptOpenState(false)
          if (httpCode === 200) {
            setLoading(true)
            const { data } = await getScripts();
            setScriptData(data?.sort((item) => item.pms ? -1 : 1));
            setLoading(false)
          }
        }} />
    </>
  );
}
