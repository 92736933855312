
import { makeRequest } from "./common";
import { CallsSettingsType, RobodialerUser, Script } from "types/types";
import { Question } from "../script-builder/create-script/newQuestionsType";

type Response<T> = {
  msg: string;
  httpCode: number;
  data: T;
};

type ScriptsResponse = Response<Array<Script>>;

type ScriptsTypesResponse = Response<{scripts: Array<{name:string, id:string}>}>;
type ScriptResponse = Response<Script>;
type CreateScriptResponse = Response<Script>;
type RobodialerUserResponse = Response<RobodialerUser>;
type CallsSettingsResponse = Response<CallsSettingsType>;



export async function makeTypedRequest(
  url: string,
  method: string,
  auth: boolean,
  data?: any
): Promise<Response<any>> {
  return (await makeRequest(url, method, auth, data)) as Response<any>;
}



export async function createRobodialerUser(data): Promise<RobodialerUserResponse> {
  return await makeTypedRequest('/robodialer/users', 'POST', false, data);
}

export async function getRobodialerUser(): Promise<RobodialerUserResponse> {
  return await makeTypedRequest('/robodialer/user', 'GET', true);
}

export async function updateRobodialerUser(data): Promise<RobodialerUserResponse> {
  return await makeTypedRequest('/robodialer/user', 'PUT', true, data);
}

export async function updateRobodialer(data): Promise<RobodialerUserResponse> {
  return await makeTypedRequest('/robodialer', 'PUT', true, data);
}

export async function refreshSecretHash(): Promise<{data:{apiSecret:string}}> {
  return await makeTypedRequest('/robodialer/refreshSecretHash', 'PUT', true, null);
}

export async function refreshTypeSecretHash(apiType): Promise<{data:{apiSecret:string}}> {
  return await makeTypedRequest('/robodialer/refreshTypeSecretHash', 'PUT', true, apiType);
}

export async function getScripts(): Promise<ScriptsResponse> {
  let scripts = await makeTypedRequest("/scripts", "GET", true);
  if (scripts?.httpCode !== 200 || Object.keys(scripts.data).length === 0) {
    scripts.data = []
  }
  return scripts
}

export async function getScriptsTypes(): Promise<ScriptsTypesResponse> {
  return await makeTypedRequest("/scripts-types", "GET", true);
}

export async function getScript(scriptId): Promise<ScriptResponse> {
  return await makeTypedRequest(`/scripts/${scriptId}`, "GET", true);
}

export async function getScriptInputs(scriptType): Promise<ScriptResponse> {
  return await makeTypedRequest(`/scripts/inputs/${scriptType}`, "GET", true);
}

export async function updateScript(scriptId, data): Promise<ScriptResponse> {
  return await makeTypedRequest(`/scripts/${scriptId}`, "POST", true, data);
}
export async function updateScriptDoc(scriptId, data): Promise<ScriptResponse> {
  return await makeTypedRequest(`/scripts/${scriptId}`, "PUT", true, data);
}

export async function inviteMember(email: string, role: string) {
  return await makeRequest(`/robodialer/team`, "POST", true, {email, role});
}

export async function addToRoboAllowList(email: string) {
  return await makeRequest(`/robodialer/team`, "POST", true, {email});
}

export async function getRobodialerUsers() {
  return await makeRequest('/robodialer/team', 'GET', true);
}

export async function createScript(
  name: string,
  scriptData: Array<Question>,
  selectedScriptType = ''
): Promise<CreateScriptResponse> {
  
  return await makeTypedRequest("/scripts", "POST", true, {name, questions: scriptData, type: selectedScriptType});
}
export async function runScript(file, scriptId, fileName = '') {
  var formData = new FormData();
  formData.append('file', file);
  const response = await makeRequest(
    `/scripts/${scriptId}/batches?file_name=${fileName}`,
    'POST',
    true,
    null,
    formData
  );
  return response;
}

export async function getBatches() {
  const response = await makeRequest(
    '/batches',
    'GET',
    true,
    null,
    {}
  );
  return response;
}

export async function getRobodialerBatchCalls(batchId) {
  const response = await makeRequest(
    `/batches/${batchId}/calls`,
    'GET',
    true,
    null,
    {}
  );
  return response;
}

export async function getSandboxCalls() {
  const response = await makeRequest(
    '/sandbox-calls',
    'GET',
    true,
    null,
    {}
  );
  return response;
}
export async function getSandboxCallsResults(id) {
  const response = await makeRequest(
    '/sandbox-calls/' + id,
    'GET',
    true,
    null,
    {}
  );
  return response;
}


export async function createSandboxCall(data) {
  return await makeRequest('/sandbox-calls', 'POST', true, data);
}

export async function getPmsResults(offset:number = 0, limit:number = 15, archived:boolean = false, callId = '') {
  let url = '/pms-results';
  if (offset || limit ) {
    url += '?';
  }
  if(offset){
    url+=`offset=${offset}&`
  }
  if(limit){
    url+=`limit=${limit}&`
  }
  if(callId){
    url+=`call_id=${callId}&`
  }
  if(archived){
    url+="archived=1"
  }
  
  const response = await makeRequest(
    url,
    'GET',
    true,
    null,
    {}
  );
  return response;
}

export async function getPmsResultsCount(archived = false) {
  let url = '/pms-results?count=1'
  if(archived){
    url+="&archived=1"
  }
  const response = await makeRequest(
    url,
    'GET',
    true,
    null,
    {}
  );
  return response;
}

export async function getPmsNewPatients() {
  const response = await makeRequest(
    '/pms-patients?new=1&limit=50',
    'GET',
    true,
    null,
    {}
  );
  return response;
}

export async function getPmsPatients(offset:number = 0, limit:number = 15, searchValue:string|null = null) {
  let url = '/pms-patients';
  if (offset || limit || searchValue) {
    url += '?';
  }
  if(offset){
    url+=`offset=${offset}&`
  }
  if(limit){
    url+=`limit=${limit}&`
  }
  if(searchValue){
    url+='q='+searchValue
  }
  
  const response = await makeRequest(
    url,
    'GET',
    true,
    null,
    {}
  );
  return response;
}

export async function getPatientsCount(searchValue?:string) {
  let url = '/pms-patients?count=1'
  if (searchValue) {
    url += '&q='+searchValue
  }
  const response = await makeRequest(
    url,
    'GET',
    true,
    null,
    {}
  );
  return response;
}

export async function dismissPatient(locId:string , id:string, ehr_id:string) {
  return await makeRequest(`/pms-patients/${locId}/${id}?ehr_id=${ehr_id}`, 'PUT', true, null);
}
export async function pauseBatch(batch_id:string , pause:boolean) {
  return await makeRequest(`/batches/${batch_id}/pause`, 'PUT', true, {
    pause
  });
}

export async function archiveCall(call_id) {
  return await makeRequest(`/archive-call/${call_id}`, 'PUT', true, null);
}

export async function dismissAllPatients() {
  return await makeRequest(`/pms-patients/dismiss-all`, 'PUT', true, null);
}

export async function checkPatientInsurance(locId, id: string, ehr_id:string) {
  return await makeRequest(`/pms-patients/check-insurance/${locId}/${id}?ehr_id=${ehr_id}`, 'PUT', true, null);
}
export async function fetchInsurance(locId:string, id:string, ehr_id:string) {
  return await makeRequest(`/pms-patients/fetch-insurance/${locId}/${id}?ehr_id=${ehr_id}`, 'PuT', true, null);
}


export async function retryPmsCalls(calls: string[]) {
  return await makeRequest(`/pms-patients/retry`, 'PUT', true, {calls});
}
export async function checkAllPatientsInsurance(locations?:{ [key: string]: string[]; }) {
  let data = locations ? {locations} : {}
  return await makeRequest(`/pms-patients/check-insurance`, 'PUT', true, data);
}

export async function enableAutoVerification() {
  return await makeRequest(`/pms-patients/enable-auto-verification`, 'PUT', true, null);
}

export async function downloadZipResults(batchId) {
  const response = await makeRequest(
    `/batches/download/${batchId}`,
    'GET',
    true,
    null,
    {},
    true
  );
  return response;
}

export async function downloadCallInputs(scriptId, batchId) {
  const response = await makeRequest(
    `/batches/download/${scriptId}/batches/${batchId}/inputs`,
    'GET',
    true,
    null,
    {}
  );
  return response;
}

export async function downloadResults(scriptId, batchId, successfulCalls = true) {
  const response = await makeRequest(
    `/batches/download/${scriptId}/batches/${batchId}?successfulCalls=${successfulCalls}`,
    'GET',
    true,
    null,
    {}
  );
  return response;
}

export async function downloadPdfResults(ehr_id, location_id, patient_id) {
  const response = await makeRequest(
    `/pms-results/download/${location_id}/patient/${patient_id}?ehr_id=${ehr_id}`,
    'GET',
    true,
    null,
    {}
  );
  
  return response;
}


export async function getPlan() {
  return await makeRequest(
    `/robodialer/user-plan`,
    'GET',
    true,
    null,
    null
  );
}

export async function userSubscribe(data) {
  return await makeRequest('/robodialer/subscription', 'POST', true, data);
}

export async function duplicateScript(scriptId, data) {
  return await makeTypedRequest(`/scripts/${scriptId}/duplicate`, "POST", true, data);
}


export async function placeDemoCall(data) {
  return await makeRequest('/robodialer/place-demo-call', 'POST', true, data);
}


export async function updateSubscription(data) {
  return await makeRequest('/robodialer/subscription', 'PUT', true, data);
}

export async function unsubscribe(data) {
  return await makeRequest('/robodialer/unsubscribe', 'POST', true, data);
}

export async function getWebhookData() {
  return await makeRequest('/robodialer/webhookData', 'GET', true, null, null);
}

export async function generateApiKeys(apiType) {
  return await makeRequest('/robodialer/apiKeys', 'POST', true, apiType);
}

export async function getCallsSettingsData(): Promise<CallsSettingsResponse> {
  return await makeTypedRequest('/robodialer/callsSettings', 'GET', true, null);
}

export async function getPmsOverview(){
  return await makeTypedRequest('/pms-overview', 'GET', true, null);
}

export async function getCallsOverview(filter){
  const url = '/calls-overview' + ( filter ? '?filter='+filter : '')
  return await makeTypedRequest(url, 'GET', true, null);
}
export async function archiveResults(data) {
  return await makeRequest('/archive-calls', 'PUT', true, data);
}

export async function signinLog(data) {
  return await makeRequest('/robodialer/users/signin-log', 'POST', false, data);
}

export async function createAgentUser(data): Promise<RobodialerUserResponse> {
  return await makeTypedRequest('/agents/users', 'POST', false, data);
}