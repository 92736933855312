import React, { useState, useEffect } from 'react';

export default function SidekickRetoolPage() {
  const retoolUser = 'andrew.clemendor@thesuperbill.com';
  // const callID = '6x1MzJ4PTwHxnMkNXfbE';
  const robodialerID = '1';

  const GETheaders = new Headers();
  GETheaders.append('Superbill-Api-Key', '1');
  GETheaders.append('Superbill-Api-Secret', 'a-Eixffm7ehhUy9fo*78!35QWRAU');
  let token = ''

  // fetch('https://superbill-service-api-gateway-9nc4t1p9.uc.gateway.dev/v1/auth', {
  //   method: 'GET',
  //   headers: GETheaders
  // }).then(res => res.json())
  //   .then(json => token = json.token);

  // const url0 = `http://127.0.0.1:8080/v1/retool/assign-call/user/${retoolUser}/call-id/${callID}/robodialer-id/${robodialerID}`;

  // const url = 'http://127.0.0.1:8080/v1/retool/assign-call/user/andrew.clemendor@thesuperbill.com/call-id/6x1MzJ4PTwHxnMkNXfbE/robodialer-id/1'


  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', `Bearer ${token}`);

  // if (token) {
  //   fetch(url, {
  //     method: 'POST',
  //     headers: headers
  //   })
  // }


  return (
    <div className="flex flex-col justify-center p-5 h-full" >
      <h1 className="font-semibold mb-2">Call Information</h1>
      <iframe
        className='flex-grow'
        title="Retool Website"
        src="https://retool.thesuperbill.com/apps/Regina%20Design%20-%20SuperDial%20Call%20Portal"
      />
    </div>
  );
}
