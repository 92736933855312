import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

type props = {
  onSubmit: (data) => void
  formData?:{
    phoneNumberValid?: string;
    lineConnected?: string;
    humanReached?: string;
    outcome?: string;
    detailedFailureReason?: string;
    mainFailureReason?: string;
    tryAgainIn?: string;
    description?: string;
    callOutcome?: string;
    betterNumberProvided?: string;
  }
  bundled?: boolean;
}

const SubmitFormComponent = ({ onSubmit, formData, bundled = false }: props) => {
  const { register, handleSubmit, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: formData,
  });
  //const [outcome, setOutcome] = useState<string>('');
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false)

  const optionsByOutcome = {
    'success': null,
    'failure': {
      options: {
        "input_missing": {
          "member_id_missing":{text: "Member ID",},
          "tax_id_missing":{text: "Tax ID",},
          "provider_id_missing":{text: "Provider ID",},
          "payer_name_missing":{text: "Payer Name",},
          "provider_id_other_missing":{text: "Provider ID - other"},
          "member_dob_missing":{text: "Member Date of Birth",},
          "member_address_missing":{text: "Member Address",},
          "provider_address_missing":{text: "Provider Address",},
          "provider_name_missing":{text: "Provider Name",},
          "facility_name_missing":{text: "Facility Name",},
          "facility_address_missing":{text: "Facility Address",},
          "email_address_missing":{text: "Email Address",},
          "form_submission_method":{text: "Form Submission Method",},
          "date_of_service_missing":{text: "Date of Service",},
          "diagnosis_code_missing":{text: "Diagnosis Code",},
          "member_group_missing":{text: "Member's Group Number",},
          "claim_number":{text: "Claim Numer",},
          "provider_ptan_medicare_missing":{text: "Provider's PTAN Number - Medicare"},
          "auth_id_missing":{text: "Auth ID"},
        },
        "input_incorrect": {
          "member_id_incorrect":{text: "Member ID",},
          "tax_id_incorrect":{text: "Tax ID",},
          "provider_id_incorrect":{text: "Provider ID",},
          "member_name_incorrect": {text: "Member Name",},
          "member_dob_incorrect":{text: "Member Date of Birth",},
          "member_address_incorrect":{text: "Member Address",},
          "provider_address_incorrect":{text: "Provider Address",},
          "provider_name_incorrect":{text: "Provider name",},
          "provider_ptan_incorrect":{text: "Provider PTAN Number-Medicare",},
          "phone_number_incorrect":{text: "Phone Number",},
          "email_address_incorrect":{text: "Email Address",},
          "form_submission_method":{text: "Form Submission Method",},
          "date_of_service_incorrect":{text: "Date of Service"},
          "facility_name_incorrect":{text: "The Facility Name"},
        },
        "phone": {
          "line_cut": {
            text: "Number doesn't work / out-of-service",
            hints: [
              <>
              <div className='font-bold text-superbill-jacarta text-sm'>Number doesn't work / out-of-service</div>
              <div className='text-superbill-jacarta text-xs'>
                The phone line cuts out immediately and you hear a message similar to "The number you have dialed is out of service", or the line just keeps ringing and ringing, or you hear the "busy" tone
              </div>
              </>
            ]
          },
          "incorrect_number": {
            text: "Incorrect number: Requested info cannot be found",
            hints: [
              <>
              <div className='font-bold text-superbill-jacarta text-sm'>Incorrect number: Requested information cannot be found</div>
              <div className='text-superbill-jacarta text-xs'>
                The representative tells you that this is the incorrect number for the information you are trying to obtain.
                Example: You are calling an insurance company, but they tell you that the member ID is not on file with them. So, it's a working number, but probably not the correct number or insurance company.
              </div>
              </>
            ]
          },
          "member_line": {
            text: "Incorrect number: Line is for member services",
            hints: [
              <>
              <div className='font-bold text-superbill-jacarta text-sm'>Incorrect number: Line is for member services</div>
              <div className='text-superbill-jacarta text-xs'>
                The representative tells you that you have reached the **member services line**. Generally, we want to use the **provider** line.
              </div>
              </>
            ]
          },
          "no_third_party": {
            text: "Information cannot be provided to 3rd party",
            hints: [
              <>
              <div className='font-bold text-superbill-jacarta text-sm'>Information cannot be provided to 3rd party</div>
              <div className='text-superbill-jacarta text-xs'>
                The representative refuses to give the requested information to you because you are a 3rd party.
              </div>
              </>
            ]
          },
          "info_not_available": {
            text: "Information only provided on Availity",
            hints: [
              <>
              <div className='font-bold text-superbill-jacarta text-sm'>Information only provided on Availity</div>
              <div className='text-superbill-jacarta text-xs'>
                The representative tells you that you must go to Availity to find the information you are trying to obtain.
              </div>
              </>
            ]
          },
          "online_portal": {
            text: "Information only provided on Online Portal (not Availity)",
            hints: [
              <>
              <div className='font-bold text-superbill-jacarta text-sm'>Information only provided on Online Portal (not Availity)</div>
              <div className='text-superbill-jacarta text-xs'>
                The representative tells you that you must go to their online portal to obtain the information (ex: their dental portal)
              </div>
              </>
            ]
          },
          "no_rep_option": {
            text: "Call is routed to voicemail",
            hints: []
          },
          "fax_only": {
            text: "Information provided by fax only",
            hints: []
          },
          "phone_not_operational": {
            text: "The phone number is not operational",
            hints: []
          },
        },
        "other": {
          "policy_termed": {text: "Policy Termed",},
          "no_rep_option_via_ivr": {text: "No option to speak to a representative via the IVR",},
          "no_prescription_benefits": {text: "No Prescription Benefits",},
          "cpt_not_covered": {text: "CPT Codes not covered under policy",},
          "medicaid_required": {text: "Medicaid ID required",},
          "passcode_required": {text: "Passcode required to reach live rep",},
          "email_only": {text: "Information provided by email request only",},
          "multiple_long_wait_times": {text: "Multiple Long Wait Times Preventing Batch From Completing On Time",},
          "rep_refuses": {text: "Representative refuses to provide info",},
          "no_oon": {text: "No out-of-network benefits"},
          "claim_no_match": {text: "Claim amount does not match"},
        },
      },
    },
    'need_to_retry': {
      options: {
        "15": "15 minutes",
        "60": "1 hour",
        //"720": "Next business day (ONLY if office is closed for the day)"
      },
    }
  };


  const getFailureOutcome = () => {
    return (
      <>
        <div>
          <label className="block text-sm font-bold text-superbill-jacarta mt-4 mb-1">Why did the call fail?:</label>
          {watch('outcome') && optionsByOutcome[watch('outcome') ?? ''] && Object.keys(optionsByOutcome[watch('outcome') ?? '']?.options).map((option) => (
            <label key={`mainFailureReason-options-${option}`} className='h-[50px] cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1 mr-2'>
              <input
                value={option}
                key={option + "-radio"}
                type="radio"
                className='mr-2'
                {...register('mainFailureReason')}
              />
              <span>{option.toLocaleUpperCase()?.replace('_', " ")}</span>
            </label>
          ))}
        </div>
        <div>
          <label className="block text-sm font-bold text-superbill-jacarta mt-4 mb-1">Select a detailed reason:</label>
          <div className='gap-4 items-center justify-start grid grid-cols-4'>
            {watch('outcome') && watch('mainFailureReason') && Object.keys(optionsByOutcome[watch('outcome') ?? '']?.options[watch('mainFailureReason') ?? ''] ?? {}).map((reason) => (
              <label key={`mainFailureReason-reasons-${reason}`} className=' h-full cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1 mr-2'>
                <input
                  value={reason}
                  key={reason + "-radio"}
                  type="radio"
                  className='mr-2'
                  {...register('detailedFailureReason')}
                />
                <span>{optionsByOutcome[watch('outcome') ?? '']?.options[watch('mainFailureReason')  ?? ''][reason].text}</span>
              </label>
            ))}
          </div>
          {watch('mainFailureReason') && watch('detailedFailureReason') && optionsByOutcome[watch('outcome') ?? '']?.options[watch('mainFailureReason')  ?? ''] && optionsByOutcome[watch('outcome') ?? '']?.options[watch('mainFailureReason')  ?? ''][watch('detailedFailureReason') ?? ''] && optionsByOutcome[watch('outcome') ?? '']?.options[watch('mainFailureReason') ?? ''][watch('detailedFailureReason') ?? '']?.hints && optionsByOutcome[watch('outcome') ?? '']?.options[watch('mainFailureReason') ?? ''][watch('detailedFailureReason') ?? '']?.hints?.map((hint) => (
            <div key={`mainFailureReason-hints-${hint}`} className='border bg-superbill-yellow p-4 my-3'>{hint}</div>
          ))}
        </div>
      </>
    )
  }

  const getNeedToRetryOutcome = () => {

    return (
      <div>
      <label className="block text-sm font-bold text-superbill-jacarta mt-4 mb-1">Re-Try in...</label>
      {watch('outcome') && optionsByOutcome[watch('outcome') ?? ''] && Object.keys(optionsByOutcome[watch('outcome') ?? '']?.options).map((key) => (
        <label key={`tryAgainIn-options-${key}`} className='cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1 mr-2'>
          <input
            value={key} 
            key={key+"-radio"}
            type="radio"
            className='mr-2'
            {...register('tryAgainIn')}
          />
          <span>{optionsByOutcome[watch('outcome') ?? '']?.options[key]}</span>
        </label>
      ))}
    </div>
    )
  }

  const screens = {
    failure: getFailureOutcome(),
    need_to_retry: getNeedToRetryOutcome(),
  }


  const callOnSubmit = async (data) => {
    setSubmitIsLoading(true)
    await onSubmit(data);
    setSubmitIsLoading(false)
  };

  const handleOutcomeChange = (option) => {
    //setOutcome(option);
    if(watch('outcome') != option){
      setValue('callOutcome', option);
      setValue('outcome', option);
      setValue('mainFailureReason', '');
      setValue('detailedFailureReason', '');
    }
  };


    useEffect(() => {
      if(!optionsByOutcome['failure'] || 
        !optionsByOutcome['failure']['options'][watch('mainFailureReason') ?? ''] ||
        !optionsByOutcome['failure']['options'][watch('mainFailureReason') ?? ''][watch('detailedFailureReason')] ){
        console.log('resetting');
        setValue('detailedFailureReason', '')
      }
    }, [watch('mainFailureReason')]);


  return (
    <form onSubmit={handleSubmit(callOnSubmit)}>
      <div className="mb-4">
        <label className="block text-sm font-bold text-superbill-jacarta mb-2">Was the phone number correct?</label>
        <label className='cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1'>
          <input
            type="radio"
            value={"true"}
            className="mr-2"
            {...register('phoneNumberValid')}
          />
          Yes
        </label>
        <label className='ml-2 cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1'>
          <input
            type="radio"
            value={"false"}
            className="mr-2"
            {...register('phoneNumberValid')}
          />
          No
        </label>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold text-superbill-jacarta mb-2">Line is connected?</label>
        <label className='cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1'>
          <input
            type="radio"
            value={"true"}
            className="mr-2"
            {...register('lineConnected')}
          />
          Yes
        </label>
        <label className='ml-2 cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1'>
          <input
            type="radio"
            value={"false"}
            className="mr-2"
            {...register('lineConnected')}
          />
          No
        </label>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold text-superbill-jacarta mb-2">Reached a human?</label>
        <label className='cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1'>
          <input
            type="radio"
            value={"true"}
            className="mr-2"
            {...register('humanReached')}
          />
          Yes
        </label>
        <label className='ml-2 cursor-pointer justify-start items-center bg-white w-full sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1'>
          <input
            type="radio"
            value={"false"}
            className="mr-2"
            {...register('humanReached')}
          />
          No
        </label>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold text-superbill-jacarta mb-2">Result:</label>
        <div className={`flex`}>
          {Object.keys(optionsByOutcome).map((option) => (
              <label key={`outcome-options-${option}`} className={`cursor-pointer justify-start items-center  w-fit sm:text-sm rounded text-superbill-jacarta hover:border-superbill-ultramarine ease-in duration-150 border border-superbill-soap px-2 py-1 mr-2 ${option==='success' ? ' bg-green-500/50 hover:bg-green-500' : option==='failure' ? ' bg-red-500/50 hover:bg-red-500' : ' bg-yellow-500/50 hover:bg-yellow-500' }`}>
                <input
                  value={option}
                  key={option + "-radio"}
                  type="radio"
                  onClick={() => handleOutcomeChange(option)}
                  {...register('outcome')}
                />
                <span className='text-sm text-superbill-jacarta capitalize ml-1'>{option?.replaceAll('_', ' ')}</span>
              </label>
          ))}
        </div>
      </div>
      <div className='p-4 rounded bg-superbill-anti-flash-white my-2 '>
        <div className='flex gap-x-2 items-center justify-start'>
          <div className='p-2 bg-green-500 rounded-full w-fit'></div>
          <span className='text-sm text-superbill-jacarta'><strong>Success:</strong> All questions were answered</span>
        </div>
        <div className='flex gap-x-2 items-center justify-start'>
          <div className='p-2 bg-superbill-ultra-red rounded-full w-fit'></div>
          <span className='text-sm text-superbill-jacarta'><strong>Failure:</strong> Unable to answer all questions with the inputs provided</span>
        </div>
        <div className='flex gap-x-2 items-center justify-start'>
          <div className='p-2 bg-yellow-500 rounded-full w-fit'></div>
          <span className='text-sm text-superbill-jacarta'><strong>Need to Retry:</strong> The office is closed or the provider is temporarily unavailable</span>
        </div>
      </div>
      {watch('outcome') && screens[watch('outcome') ?? ''] ? (
        <div className="mb-4">
          <label className="block text-sm font-bold text-superbill-jacarta mb-2">Outcome details:</label>
          {screens[watch('outcome') ?? '']}
        </div>
      ) : null}
      <div className="mb-4">
        <label className="block text-sm font-bold text-superbill-jacarta mb-2">Description:</label>
        <textarea
          {...register('description')}
          placeholder="Enter audit notes here (required)"
          className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base w-full"
        />
      { watch('outcome') &&
        watch('outcome') === 'need_to_retry' || 
        (( watch('outcome') === 'failure' && watch('mainFailureReason') === 'phone' ) ||
         ( watch('outcome') === 'failure' && watch('mainFailureReason') === 'input_incorrect' && watch('detailedFailureReason') === 'phone_number_incorrect')) ? 
        <div className='p-4 rounded bg-superbill-anti-flash-white my-2 '>
          <div className='flex items-center justify-center gap-x-4 p-4 bg-superbill-lavender rounded mt-4'>
            <span className='text-sm font-jacarta text-superbill-jacarta'>If the call could not be completed with the current number and you were provided with the correct one, please enter it below for the next retry attempt</span>
          </div>
          <div className='mt-2 mb-1'>
            <div className="block text-sm font-bold text-superbill-jacarta">Please enter the 10-digit phone number without the country code, plus sign, parentheses, or hyphens.</div>
            <div className="block text-sm italic text-superbill-jacarta"> For example, if the number is +1-(800)-123-1234, enter 8001231234</div>
          </div>

          <input
            {...register('betterNumberProvided')}
            maxLength={10}
            placeholder="Enter 10-digit phone number OR JUST LEAVE BLANK"
            className="rounded border-superbill-soap focus:border-superbill-indigo focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-base w-full"
          />
          {watch('betterNumberProvided') !== '' && (watch('betterNumberProvided')?.length !== 10 || !/^\d{10}$/.test(watch('betterNumberProvided') ?? "")) && (
            <p className="text-red-500 text-sm mt-1">Please enter exactly 10 digits with no spaces or special characters</p>
          )}

        </div>
      :null}
      </div>
      <div className='w-full flex items-center justify-center'>
        <button
          disabled={submitIsLoading || 
          !watch('phoneNumberValid') ||
          !watch('description') ||
          !watch('lineConnected') ||
          !watch('humanReached') ||
          !watch('outcome') ||
          (watch('outcome') === 'failure' && (!watch('mainFailureReason') || !watch('detailedFailureReason'))) ||
          (watch('outcome') === 'need_to_retry' && !watch('tryAgainIn'))

        }
          type="submit"
          className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
        >
          {submitIsLoading ? <Spinner /> : 
            <>
              {!watch('outcome') ? 'Submit' : null}
              {watch('outcome') === 'success' ? 'Mark as customer success' : null}
              {watch('outcome') === 'need_to_retry' ? 'Send back to queue' : null}
              {watch('outcome') === 'failure' ? 'Mark as customer fail' : null}
              {bundled ? ' and load next call in bundle' : null}
            </>
          }
        </button>
      </div>
    </form>
  );
};

export default SubmitFormComponent;
